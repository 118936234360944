<template>
  <div class="">
    <TopBarTitleComponent title="Productos" :showBackIcon="true">
      <template slot="right-area">
        <div class="d-flex justify-content-end align-items-center">
          <ButtonPrimaryComponent title="Nuevo Producto" @onSaveClick="goRouter('catalogue.products.add')" />
        </div>
      </template>
    </TopBarTitleComponent>

    <b-container fluid class="pb-4 px-lg-4">
      <section class="input-filters">
        <InputSearchComponent @onChangeSearch="onChangeSearch" />
        <SelectOrderByComponent @onSelectOrderBy="onSelectOrderBy" />
        
        <div class="d-flex justify-content-center align-items-center">
          <button type="button" class="mx-2 my-0 float-right cursor-point bg-light p-2 border-0 rounded-circle" v-if="activetrash"
          @click="productsDeleteOuterVisible = true">
          <img :src="icoTrash" alt="ico trash" />
        </button>
          <ButtonPrimaryComponent title="Excel" style="min-height: 40px !important;" class="border"
            @onSaveClick="downloadFile" />
          <ButtonInfoComponent title="PDF" style="min-height: 40px !important; margin-left: 10px;"
            @onClick="onDownloadPDF" />

        </div>
      </section>
      <template v-if="apiResponse.success">
        <template v-if="items.length">
          <section class="table-items table-items-catalogue">
            <b-row class="table-items-header line-bottom">
              <b-col xs="12" sm="12" md="1" class="d-flex justify-content-center">
                <b-form-checkbox v-model="checkAll" label="" size="lg" class="custom-checkbox ml-3"
                  @change="handleCheckAllChange" />
              </b-col>
              <b-col xs="12" sm="12" md="1">
                <h6 class="h6">Código</h6>
              </b-col>
              <b-col xs="12" sm="12" md="1">
                <h6 class="h6">Imágen</h6>
              </b-col>
              <b-col xs="12" sm="12" md="4">
                <h6 class="h6">Nombre</h6>
              </b-col>
              <b-col xs="12" sm="12" md="1">
                <h6 class="h6">Borrador/Publicar</h6>
              </b-col>
              <b-col xs="12" sm="12" md="2">
                <h6 class="h6">Categoría</h6>
              </b-col>
              <b-col xs="12" sm="12" md="1">
                <h6 class="h6">Valor</h6>
              </b-col>
              <b-col xs="12" sm="12" md="1">
                <h6 class="h6">Gestión de inventario</h6>
              </b-col>
            </b-row>
            <div class="card table-items-body" v-for="(product, index) in resultQueryProducts" :index="index"
              :key="product.id">
              <div class="card-body table-items-content">
                <b-row class="">
                  <b-col xs="12" sm="12" md="1" class="d-flex justify-content-center align-items-center font-semi-bold">
                    <b-form-checkbox v-model="productSelect" :value="product.id" size="lg" >
                    </b-form-checkbox>
                  </b-col>

                  <b-col xs="12" sm="12" md="1" class="table-col-item" @click="
                      goRouter('catalogue.products.edit', { id: product.id })
                    ">
                    <span class="item-col-code font-semi-bold">
                      {{ product.code }}
                    </span>
                  </b-col>
                  <b-col xs="12" sm="12" md="1" class="table-col-item" @click="
                      goRouter('catalogue.products.edit', { id: product.id })
                    ">
                    <ImagePreviewCircleComponent :urlImage="product.image" />
                  </b-col>
                  <b-col xs="12" sm="12" md="4" class="table-col-item" @click="
                      goRouter('catalogue.products.edit', { id: product.id })
                    ">
                    <span class="product-name font-bold str-limit-space">
                      {{ product.name }}
                    </span>
                  </b-col>
                  <b-col xs="12" sm="12" md="1" class="table-col-item">
                    <div class="d-flex align-items-center">
                      <SwitchParametro :setValue.sync="product.status_product" :setStatusId="product.id"
                        @onChange="onChange" />
                    </div>
                  </b-col>
                  <b-col xs="12" sm="12" md="2" class="table-col-item">
                    <span class="product-category font-semi-bold">
                      <span class="d-inline-block d-md-none text-muted">
                        Categoría:
                      </span>
                      {{ getCategories(product) }}
                    </span>
                  </b-col>
                  <b-col xs="12" sm="12" md="1" class="table-col-item" @click="
                      goRouter('catalogue.products.edit', { id: product.id })
                    ">
                    <span class="product-amount font-semi-bold">
                      ${{ product.amount }}
                    </span>
                  </b-col>
                  <b-col xs="12" sm="12" md="1" class="table-col-item">
                    <span class="product-stock font-semi-bold" v-if="product.is_stock_counter">
                      <el-button size="small" type="primary" @click="showModal(product.stocks)" icon="el-icon-search">
                      </el-button>
                    </span>
                  </b-col>
                </b-row>
              </div>
            </div>

            <Pagination :pagination="pagination" @change="onChangePaginationPage" />
          </section>
        </template>
        <EmptyPage :media="emptyMedia2" text="Registras tus productos o servicios  y empieza a ofrecer a tus clientes."
          v-else />
      </template>
      <LoadingSkeletonTable v-else />
    </b-container>

    <WrapperCatalogTourComponent />

    <el-dialog width="200" title="" :visible.sync="productsDeleteOuterVisible" class="">
      <div class="wrapper__invoice-delete text-center">
        <img :src="icoNotifyDanger" alt="ico danger" width="30" />
        <h4 class="h4 font-semi-bold text-break text-color-primary mt-4" v-if="productSelect.length > 0">
          ¿Seguro desea eliminar estos registros?
        </h4>
        <h4 class="h4 font-semi-bold text-break text-color-primary mt-4" v-else>
          Debe selecionar al menos un producto!!
        </h4>
        <div class="d-flex justify-content-center align-items-center mt-4">
          <button @click="confirmDelete" class="btn md__btn-secondary mr-4">
            Cancelar
          </button>
          <ButtonPrimaryComponent title="Eliminar" @onSaveClick="handleProductDelete" v-if="productSelect.length > 0" />
        </div>
      </div>
    </el-dialog>

    <el-dialog title="Inventario" :visible.sync="dialogVisible">
      <el-table :data="stocks" stripe style="width: 100%">
        <el-table-column prop="establishment" label="Establecimiento">
        </el-table-column>
        <el-table-column prop="quantity" label="Cantidad"> </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import TopBarTitleComponent from "../../../components/TopBarTitle";
import InputSearchComponent from "../../../components/InputSearch";
import ImagePreviewCircleComponent from "../../../components/ImagePreviewCircle";
import SelectOrderByComponent from "../../../components/SelectOrderBy";
import LoadingSkeletonTable from "@/components/Animations/Loading/SkeletonTable";
import ButtonPrimaryComponent from "../../../components/ButtonPrimary";
import catalogueService from "../../services/catalogueService";
import SwitchParametro from "./SwitchParametros";
import WrapperCatalogTourComponent from "../WrapperTour";
import EmptyPage from "../../../components/EmptyPage";
import Pagination from "@/components/General/Pagination.vue";
import { emptyMedia2 } from "@/services/resources";
import ButtonInfoComponent from "@/components/Buttons/ButtonInfo";
import { icoNotifyDanger, icoTrash } from "@/services/resources";

export default {
  name: "ProductListComponent",
  data: () => ({
    icoNotifyDanger,
    icoTrash,
    activetrash: false,
    filters: {
      search: null,
      page: 1
    },
    pagination: null,
    emptyMedia2,
    eraserPublic: {
      id: "",
      status_product: false
    },
    apiResponse: {
      success: false
    },
    orderByQuery: null,
    items: [],
    dialogVisible: false,
    stocks: [],
    checkAll: false,
    productSelect: [],
    productsDeleteOuterVisible: false
  }),
  computed: {
    resultQueryProducts() {
      let products = this.items;
      return products.concat().sort((a, b) => {
        switch (this.orderByQuery) {
          case "recent":
            return a.id < b.id ? 1 : -1;
          case "old":
            return a.id > b.id ? 1 : -1;
          case "a-z":
            return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
          case "z-a":
            return a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1;
        }
      });
    }
  },
  watch: {
    productSelect() {
      this.checkAll = this.productSelect.length === this.resultQueryProducts.length;
      this.activetrash = this.productSelect.length > 0;
    }
  },
  methods: {
    toggleApiResponse(property, value) {
      this.apiResponse[property] = value;
    },
    goRouter(name, params = {}) {
      this.$router.push({ name, params });
    },
    onChangeSearch({ search }) {
      this.filters.search = search;
      this.handleGetProducts();
    },
    onSelectOrderBy({ orderBy }) {
      this.orderByQuery = orderBy;
    },
    handleCheckAllChange() {
      if (this.checkAll) {
        this.productSelect = this.resultQueryProducts.map(
          product => product.id
        );
      } else {
        this.productSelect = [];
      }

 
    },
    confirmDelete() {
      this.productsDeleteOuterVisible = !this.productsDeleteOuterVisible;
    },
    async onChange({ value, codigo }) {
      try {
        this.eraserPublic.id = codigo;
        this.eraserPublic.status_product = value;
        let response = await catalogueService.getEraserPublic(
          this.eraserPublic
        );
        const { success, message, product } = response.data;
        if (success) {
          this.product = product;
          this.$notifications.success({
            message
          });
        }
      } catch (error) {
        return false;
      }
    },
    getCategories({ user_business_category = [] }) {
      return user_business_category.map(c => c.title).toString();
    },
    async handleGetProducts() {
      this.activetrash = false;
      const { page, search } = this.filters;

      try {
        let response = await catalogueService.getProducts({
          params: {
            page,
            search
          }
        });
        const { products, pagination } = response?.data;

        if (products) {
          this.items = products;
          this.pagination = pagination;
          this.toggleApiResponse("success", true);
        }
      } catch (error) {
        return false;
      }
    },
    async handleProductDelete() {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let data = {
          ids: this.productSelect
        };

        let response = await catalogueService
          .deleteProductsIds(data)
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
            this.confirmDelete();
          });

        const { success, message } = response.data;

        if (success) {
          this.$notifications.success({
            message
          });
        }
        this.handleGetProducts();
      } catch (error) {
        return false;
      }
      //   try {
      //     setTimeout(
      //       this.productSelect.forEach(val => this.onProductDelete(val)),
      //       1000
      //     );
      //     let message = "Productos eliminados";

      //     this.$notifications.success({ message });
      //     this.productsDeleteOuterVisible = false;

      //     this.handleGetProducts();
      //   } catch (error) {
      //     this.$notifications.error(error);
      //   }
    },
    // async onProductDelete(productId) {
    //   try {
    //     let response = await catalogueService.deleteProductId(productId);
    //     const { success } = response.data;

    //     return success;
    //   } catch (error) {
    //     return false;
    //   }
    // },
    dateNow() {
      return new Date()
        .toISOString()
        .slice(0, 19)
        .replace(/-/g, "_")
        .replace("T", "_");
    },
    async onDownloadPDF() {
      const { search } = this.filters;
      this.loading = true;

      try {
        const response = await catalogueService
          .downloadPdfProduct({
            params: {
              ...{},
              ...search
            }
          })
          .finally(() => {
            this.loading = false;
          });

        const { success, data } = response.data;
        if (success) {
          const date = this.dateNow();
          const anchor = document.createElement("a");
          const filename = `${date}_descarga_listado_productos.pdf`;
          anchor.setAttribute("download", filename);
          anchor.setAttribute("href", data);
          anchor.innerHTML = filename;
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
        }
      } catch (error) {
        return false;
      }
    },
    async downloadFile() {

      this.loading = true;

      try {
        const response = await catalogueService
          .getDownloadReportInvoicesExcel({
            params: {
              ...{},
              ...this.searchDates
            },
            headers: {
              responseType: "blob",
              ContentType: "application/vnd.ms-excel"
            }
          })
          .finally(() => {
            this.loading = false;
          });

        const { data, success } = response.data;
        if (success) {
          const date = this.dateNow();
          const anchor = document.createElement("a");
          const filename = `${date}_descarga_listado_productos.xlsx`;
          anchor.setAttribute("download", filename);
          anchor.setAttribute("href", data);
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
        }
      } catch (error) {
        return false;
      }
    },
    onChangePaginationPage(page) {
      this.filters.page = page;
      this.handleGetProducts();
    },
    showModal(data) {
      this.stocks = data;
      this.dialogVisible = true;
    },
    handleClose(done) {
      this.$confirm("Are you sure to close this dialog?")
        .then(() => {
          done();
        })
        .catch(() => {});
    }
  },
  components: {
    TopBarTitleComponent,
    InputSearchComponent,
    ImagePreviewCircleComponent,
    SelectOrderByComponent,
    LoadingSkeletonTable,
    ButtonPrimaryComponent,
    WrapperCatalogTourComponent,
    EmptyPage,
    SwitchParametro,
    Pagination,
    ButtonInfoComponent
  },
  mounted() {
    this.handleGetProducts();
  }
};
</script>

<style scoped lang="scss">
.table-items {
  .table-items-body {
    .table-items-content {
      .item-col-code {
        width: 100%;
        // text-align: center;
      }
      .table-col-item {
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
